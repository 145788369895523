<template>
    <div @scroll="handleScroll">
      <!-- MENU -->
 
      <MenuSection :color="color" :logo="logo" :key="color" :active-route="activeRoute"
         @update-active-route="setActiveRoute" @update-language="setActiveLanguage" :active-language="activeLanguage"></MenuSection>

  
      <!-- INTRO -->
      <div id="intro" class="section light">
        <b-container>
          <b-row class="justify-content-center text-center">
            <b-col lg="9">
              <h3 class="pb-4">{{ translations.intro2.title }}</h3>
              <h5>{{ translations.intro2.description }}</h5>
            </b-col>
          </b-row>
        </b-container>
      </div>
  
      <!-- COLLAGE -->
      <CollagePage class="section light bot" style="padding-bottom: 6%;"></CollagePage>
  
      <!-- MISSION, VISION, VALUES -->
      <div id="proposito" class="section dark">
        <b-container>
          <b-row class="justify-content-center">
            <b-col lg="7" style="padding-bottom: 4%;" class="aq">
              <h6>{{ translations.purpose.purpose }} <br><span>{{ translations.purpose.purposeDescription }}</span></h6>
            </b-col>
          </b-row>
          <b-row class="justify-content-center">
            <b-col lg="7" style="padding-bottom: 4%;" class="aq">
              <h6>{{ translations.purpose.mission }} <br><span>{{ translations.purpose.missionDescription }}</span></h6>
            </b-col>
          </b-row>
          <b-row class="justify-content-center">
            <b-col lg="7" style="padding-bottom: 4%;" class="aq">
              <h6>{{ translations.purpose.vision }} <br><span>{{ translations.purpose.visionDescription }}</span></h6>
            </b-col>
          </b-row>
        </b-container>
  
        <!-- VALUES -->
        <b-container class="section dark">
          <b-row class="justify-content-center down">
            <h6>{{ translations.purpose.title }}</h6>
          </b-row>
          <b-row class="justify-content-center">
             
            <b-col lg="4" md="6">
              <div class="prop-card" style="background-color: #FFFFFF;">
                <h4><img style="max-height: 100px; max-width: 80px;" src="../assets/img/1.png"></h4>
                <h2>{{ translations.values.truthfulness.title }}</h2>
                <div class="espacio"></div>
                <p>{{ translations.values.truthfulness.description }}</p>
              </div>
            </b-col>
            <b-col lg="4" md="6">
              <div class="prop-card" style="background-color: #00DD90;">
                <h4><img style="max-height: 100px; max-width: 80px;" src="../assets/img/2.png"></h4>
                <h2>{{ translations.values.quality.title }}</h2>
                <div class="espacio"></div>
                <p>{{ translations.values.quality.description }}</p>
              </div>
            </b-col>
            <div class="w-100"></div>
            <b-col lg="4" md="6">
              <div class="prop-card" style="background-color: #FFD8F4;">
                <h4><img style="max-height: 100px; max-width: 80px;" src="../assets/img/3.png"></h4>
                <h2>{{ translations.values.commitment.title }}</h2>
                <div class="espacio"></div>
                <p>{{ translations.values.commitment.description }}</p>
              </div>
            </b-col>
            <b-col lg="4" md="6">
              <div class="prop-card" style="background-color:#B1EFE2;">
                <h4><img style="max-height: 100px; max-width: 80px;" src="../assets/img/4.png"></h4>
                <h2>{{ translations.values.teamwork.title }}</h2>
                <div :class="dynamicClass" ></div>
                <p>{{ translations.values.teamwork.description }}</p>
              </div>
            </b-col>
            <b-col lg="4" md="6">
              <div class="prop-card" style="background-color:#5E8EF8;">
                <h4><img style="max-height: 100px; max-width: 80px;" src="../assets/img/5.png"></h4>
                <h2>{{ translations.values.results.title }}</h2>
                <div class="espacio"></div>
                <p>{{ translations.values.results.description }}</p>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
  
      <!-- TEAM -->
      <div id="equipo" class="section light">
        <b-container>
          <b-row class="justify-content-center text-center">
            <b-col lg="8">
              <h2>{{ translations.team.title }}</h2>
              <p>{{ translations.team.description }}</p>
            </b-col>
          </b-row>
          <b-row class="justify-content-center">
            <b-col lg="10">
              <div class="video"></div>
            </b-col>
          </b-row>
        </b-container>
        <CertificacionesSection :active-language="activeLanguage"></CertificacionesSection>
        <ReconocimientosSection :active-language="activeLanguage"></ReconocimientosSection>
      </div>
  
      <!-- CONTACT -->
      <ContactoSection class="section light" :active-language="activeLanguage"></ContactoSection>
  
      <!-- FOOTER -->
      <FooterSection :active-language="activeLanguage"></FooterSection>
    </div>
  </template>
 

<script>
    import CollagePage from "./CollagePage.vue";
    import MenuSection from "./MenuSection.vue"
    import ContactoSection from "./ContactoSection.vue"
    import FooterSection from "./FooterSection.vue"
    import CertificacionesSection  from "./CertificacionesSection.vue"
    import ReconocimientosSection  from "./ReconocimientosSection.vue"
    import esp from '../assets/json/es.json';
    import en from '../assets/json/en.json';

    export default{
        components:{
            MenuSection, CollagePage, ContactoSection, FooterSection,CertificacionesSection,ReconocimientosSection
        },    data() {
        return {
            color: 'light',
            logo: require('../assets/img//img_logo_color.svg'),
            activeRoute: localStorage.getItem('activeRoute') || 'home',
            activeLanguage: localStorage.getItem('selectedLanguage') || 'eng',
            translations: localStorage.getItem('selectedLanguage') === 'esp' ? esp : en,
        }
    },
    computed:{
      dynamicClass(){
        return this.activeLanguage === 'esp' ? 'espacio2' : 'espacio';
      }
    },
    mounted() {const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
          this.setActiveLanguage(savedLanguage);
        } else {
      this.translations = this.activeLanguage === 'eng' ? esp : en;
    }
    window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
 
      
      setActiveRoute(route) {
         this.activeRoute = route;
         localStorage.setItem('activeRoute', route);
       },
       handleScroll() {
      const sections = document.querySelectorAll('.section');
      let currentSection = null;

      sections.forEach(section => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= 50 && rect.bottom >= 50) {
          currentSection = section;
        }
      });

      if (currentSection) {
        this.toggleColor(currentSection);
      }
    },
        toggleColor(section) {
            if (section.classList.contains('dark')) {
                this.color = 'dark';
                this.logo = require('../assets/img//img_logo_white.svg');
            } else {
                this.color = 'light';
                this.logo = require('../assets/img//img_logo_color.svg');
            }
        },
       setActiveLanguage(language) {
      this.activeLanguage = language;
      this.translations = language === "eng" ? en : esp;
        localStorage.setItem('selectedLanguage', language);
    }
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll);
    }
    }

</script>

<style scoped>


h2{
    font-size: 40px;
     
    font-weight: 600;
    line-height: 45px;
}

h3{
    font-size: 40px;
    font-weight: 600;
    line-height: 45px;
}

h4{
 
    font-size: 25px;
    font-weight: 600;
    line-height: 35px;
 
    margin: 0;
}
 
h5{
 
    font-size: 25px;
    font-weight: 400;
    line-height: 34.15px;
    text-align: center;
    color:#191334;
}
 h6{
    font-size: 20px;
    font-weight: 600;
    line-height: 27.32px;
    text-align: left;
    color: #FFFFFF;
 }

 p{
 
    color:#191334;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.59px;
 


 }

.espacio2{
  display: none;
}
 
 span{
    font-size: 35px;
    font-weight: 400;
    line-height: 47.81px;
    text-align: left;
 }

 .down{
  margin-bottom: -1%;
 }

 #intro{
    padding-top: 10%;
    padding-bottom: 5%;
 }

 #proposito{
    padding-top: 7%;
    padding-bottom: 7%;
    background-color: #01146E;
    background-image: url('../assets/img//bg elements.png');
    background-repeat: no-repeat;
    background-size: cover;
 }

 .prop-card{
    border-radius: 20px;
    height: 450px;
    padding: 9%;
    margin-top: 30px;
 }

 .espacio{
    height: 15%;
 }

 #equipo{
    padding-top: 6%;
    padding-bottom: 6%;
 }

 .video{
    background-color: #1D34A2;
    background-image: url("../assets/img/Focus_1288.jpg"); 
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
    height: 518px;
    border-radius: 20px;
    margin-top: 3%;
    width: 1004px;
 }

 @media (max-width: 1200px) {

#intro{
  padding-top: 15%;
  padding-bottom: 10%;
} .video{
    background-color: #1D34A2;
    background-image: url("../assets/img/Focus_1288.jpg"); 
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
    height: 318px;
    border-radius: 20px;
    margin-top: 3%;
    width: auto;
 }
}

@media(max-width:768px){
    h2{
 
font-size: 35px;
line-height: 40px;
    }

    h3{
 
 font-size: 35px;
  
 line-height: 40px;
  
 
     }
     h4{
 
 font-size: 20px;
      line-height: 28px;
  
 
     }

     p{
        font-size: 16px;
line-height: 21.32px;
    }

    span{
    font-size: 30px;
    line-height: 40.81px;
 
 }
.espacio2{
  display: flex;
  height: 15%;  
}
 #intro{
    padding-top: 27%;
    padding-bottom: 5%;
 }

 .pb-4{
  padding-bottom: 10px !important;
 }

 h5{
  font-size: 18px !important;
  line-height: 24px !important;
 }

 .bot{
  padding-bottom: 17% !important;
 }
 #proposito {
    padding-top: 20%;
    padding-bottom: 20%;
 
 }

 .aq{
  padding-bottom: 10% !important;
 } .prop-card{
     
    height:300px;
 
 
 } #equipo{
    padding-top: 16%;
    padding-bottom: 5%;
 }
 .video{
    background-color: #1D34A2;
    background-image: url("../assets/img/Focus_1288.jpg"); 
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
    height: 318px;
    border-radius: 20px;
    margin-top: 3%;
    width: auto;
 }.down{
  margin-bottom: -4%;
 }
}


@media(max-width:500px){
  .prop-card{
   
   height:350px;


}
   
}

</style>