<template>
    <div>
        <!--MENU-->
 
        <MenuSection :color="color" :logo="logo" :key="color" :active-route="activeRoute"
         @update-active-route="setActiveRoute" @update-language="setActiveLanguage" :active-language="activeLanguage"></MenuSection>


        <div class="contacto">
            <b-container  >
                <div class="m">

                 
                <b-row class="pb-5">
                    <b-col>
                        <h1>{{ translations.contact.title }}</h1>
                    </b-col>
                </b-row>
                <b-row class="desk">
                    <b-col lg="5">
                        <p style="font-weight: 700;">{{ translations.contact.locations.tijuana }}</p>
                        <a href="tel:+526646342930" target="_blank"> <p><img src="../assets/img//phone.svg" style="margin-right: 10px;"   alt="phone icon">(664) 634 2930 / (664) 634 2815</p></a>
                        <a href="mailto:contactotij@focus.com.mx" target="_blank"> <p><img src="../assets/img//mail.svg" style="margin-right: 10px;" alt="mail icon">contactotij@focus.com.mx</p></a>
                        <a href="https://maps.app.goo.gl/xXDWwPygY71ABuBLA" target="_blank"> <p><img src="../assets/img//send.svg" style="margin-right: 10px;"  alt="send icon">Santa María #2841 Col. América <br> Tijuana B.C. 22044</p></a>
                        <br><br><br>
                        <a :href="$router.resolve({ name: 'avisoprivacidad' }).href"> <h6>{{ translations.contact.privacyNotice }} <br><br> </h6></a> <span>{{ translations.contact.rightsReserved }}</span> <br><span>{{ translations.contact.rightsReserved2 }}</span> 
                    </b-col>
                    <b-col lg="7" style="margin-top: -5%;">
                        <b-form>
                            <b-form-group>
                                <div style="display: none;">
                                    <b-form-input
                                        id="honeypot"
                                        v-model="honeypot"
                                        placeholder=" "
                                    ></b-form-input>
                                </div>
                            </b-form-group>
                            <b-row>
                                <b-col>
                                    <b-form-group id="input-group-1">
                                        <div class="form-floating align-items-center">
                                            <b-form-input
                                                id="input-1"
                                                v-model="nombre"
                                                placeholder=" "
                                                required
                                            ></b-form-input>
                                            <label for="floatingInput">{{ translations.contact.formLabels.name }}</label>
                                        </div>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group id="input-group-2">
                                        <div class="form-floating align-items-center">
                                            <b-form-input
                                                id="input-2"
                                                v-model="cargo"
                                                placeholder=" "
                                            ></b-form-input>
                                            <label for="floatingInput">{{ translations.contact.formLabels.position }}</label>
                                        </div>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col>
                                    <b-form-group id="input-group-3">
                                        <div class="form-floating align-items-center">
                                            <b-form-input
                                                id="input-3"
                                                v-model="empresa"
                                                placeholder=" "
                                            ></b-form-input>
                                            <label for="floatingInput">{{ translations.contact.formLabels.company }}</label>
                                        </div>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group id="input-group-4">
                                        <div class="form-floating align-items-center">
                                            <b-form-input
                                                id="input-4"
                                                v-model="ubicacion"
                                                placeholder=" "
                                            ></b-form-input>
                                            <label for="floatingInput">{{ translations.contact.formLabels.location }}</label>
                                        </div>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col>
                                    <b-form-group id="input-group-5">
                                        <div class="form-floating">
                                            <b-form-input
                                                id="input-5"
                                                v-model="correo"
                                                type="email"
                                                placeholder=" "
                                                required
                                            ></b-form-input>
                                            <label for="floatingInput">{{ translations.contact.formLabels.email }}</label>
                                        </div>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group id="input-group-6">
                                        <div class="form-floating">
                                            <b-form-input
                                                id="input-6"
                                                v-model="telefono"
                                                placeholder=" "
                                                required
                                            ></b-form-input>
                                            <label for="floatingInput">{{ translations.contact.formLabels.phone }}</label>
                                        </div>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <b-form-group id="input-group-7">
                                        <div class="form-floating texta">
                                            <b-form-textarea
                                                id="input-7"
                                                v-model="mensaje"
                                                placeholder=" "
                                                rows="2"
                                                max-rows="4"
                                            ></b-form-textarea>
                                            <label for="input-5">{{ translations.contact.formLabels.message }}</label>
                                        </div>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-button class="boton enviar mt-3" v-on:click="sendMessage">{{ translations.contact.sendMessage }}</b-button>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row class="phone">
                    <b-col lg="5" class="pb">
                        <p style="font-weight: 700;">{{ translations.contact.locations.tijuana }}</p>
                        <p><img src="../assets/img//phone.svg" style="margin-right: 10px;" alt="phone icon">{{ translations.contact.contactDetails.phone }}</p>
                        <p ><img src="../assets/img//mail.svg" style="margin-right: 10px;" alt="mail icon"><span class="underline"> {{ translations.contact.contactDetails.email }}</span></p>
                        <p><img src="../assets/img//send.svg" style="margin-right: 10px;" alt="send icon">{{ translations.contact.contactDetails.address }}</p>
 
                    </b-col>
                    <b-col lg="12" class="telefono pb">
                    <b-form>
                        <b-form-group>
                            <div style="display: none;">
                            <b-form-input
                                id="honeypot"
                                v-model="honeypot"
                                placeholder=" "
                            ></b-form-input>
                            </div>
                        </b-form-group>
                        <b-row>
                            <b-col>
                                <b-form-group id="input-group-1">
                                    <div class="form-floating align-items-center">  
                                        <b-form-input
                                        id="input-1"
                                        v-model="nombre"
                                        placeholder=" "
                                        required
                                        ></b-form-input>
                                        <label for="floatingInput">{{ translations.contact.formLabels.name }}</label>
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row> 
                        <b-row>
                            <b-col>
                                <b-form-group id="input-group-6">
                                    <div class="form-floating">  
                                        <b-form-input
                                            id="input-6"
                                            v-model="telefono"
                                            placeholder=" "
                                            required
                                        ></b-form-input>
                                        <label for="floatingInput"> {{ translations.contact.formLabels.phone }}</label>
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group id="input-group-5">
                                    <div class="form-floating">  
                                        <b-form-input
                                            id="input-5"
                                            v-model="correo"
                                            type="email"
                                            placeholder=" "
                                            required
                                        ></b-form-input>
                                        <label for="floatingInput"> {{ translations.contact.formLabels.email }} </label>
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row> 
                            <b-col>
                                <b-form-group id="input-group-2">
                                    <div class="form-floating align-items-center">  
                                        <b-form-input
                                        id="input-2"
                                        v-model="cargo"
                                        placeholder=" "
                                        ></b-form-input>
                                        <label for="floatingInput">{{ translations.contact.formLabels.position }}</label>
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                         
                        <b-row>
                            <b-col>
                                <b-form-group id="input-group-3">
                                    <div class="form-floating align-items-center">  
                                        <b-form-input
                                        id="input-3"
                                        v-model="empresa"
                                        placeholder=" "
                                        ></b-form-input>
                                        <label for="floatingInput">{{ translations.contact.formLabels.company }}</label>
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row> 
                            <b-col>
                                <b-form-group id="input-group-4">
                                    <div class="form-floating align-items-center">  
                                        <b-form-input
                                        id="input-4"
                                        v-model="ubicacion"
                                        placeholder=" "
                                        ></b-form-input>
                                        <label for="floatingInput">{{ translations.contact.formLabels.location }}</label>
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
 
                        <b-row>
                            <b-col>
                                <b-form-group id="input-group-7">
                                    <div class="form-floating texta"  >
                                        <b-form-textarea 
                                        id="input-7"
                                        v-model="mensaje"
                                        placeholder=" "
                                        rows=2
                                        max-rows=2
                                        
                                        ></b-form-textarea>
                                        <label for="input-5">{{ translations.contact.formLabels.message }}</label>
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>

 
                        <b-button class="boton enviar btn-inicio" v-on:click="sendMessage">{{ translations.contact.sendMessage }} </b-button>
                    </b-form>   
                    <br> 
                        <a :href="$router.resolve({ name: 'avisoprivacidad' }).href"> <h6>{{ translations.contact.privacyNotice }} <br><br> </h6></a> <span>{{ translations.contact.rightsReserved }}</span> <br><span>{{ translations.contact.rightsReserved2 }}</span> 
                </b-col>
                </b-row>
                 </div>
            </b-container>
        </div>
    </div>
</template>


<script>
import MenuSection from "./MenuSection.vue";
import esp from '../assets/json/es.json';
import en from '../assets/json/en.json';
import axios from 'axios'
export default {
    components: {
        MenuSection
    },
    data() {
        return {
            color: 'light2',
            logo: require('../assets/img//img_logo_color.svg'),
            activeRoute: localStorage.getItem('activeRoute') || 'home',
            activeLanguage: localStorage.getItem('selectedLanguage') || 'eng',
            translations: localStorage.getItem('selectedLanguage') === 'esp' ? esp : en,
            nombre: '',
            cargo: '',
            empresa: '',
            ubicacion: '',
            correo: '',
            telefono: '',
            mensaje: '',
            honeypot: ''
        };
    },
    methods: {
        setActiveRoute(route) {
            this.activeRoute = route;
            localStorage.setItem('activeRoute', route);
        },
        setActiveLanguage(language) {
            this.activeLanguage = language;
            this.translations = language === "eng" ? en : esp;
            localStorage.setItem('selectedLanguage', language);
        },
        async sendMessage() {
    if (this.honeypot) {
      console.log("Honeypot triggered.");
      return;
    }

    if (this.nombre !== '' && this.telefono !== '' && this.correo !== '') {
      try {
        const response = await axios.post('phpmailer.php', {
          nombre: this.nombre,
          cargo: this.cargo,
          empresa: this.empresa,
          ubicacion: this.ubicacion,
          correo: this.correo,
          telefono: this.telefono,
          mensaje: this.mensaje
        });

        console.log(response.data); // Logging the response data

        if (response.data == 200 || response.data == '200') {
            if (this.activeLanguage === 'esp') {
            this.$router.push('/gracias');
          } else {
            this.$router.push('/thankyou');
          }
        } else {
            this.messageSend = 'Error al enviar mensaje, intenta más tarde.';
             alert('Error al enviar mensaje, intenta más tarde.');
        }
      } catch (error) {
        console.error(error);
        this.messageSend = 'Error al enviar mensaje, intenta más tarde.';
            alert('Error al enviar mensaje, intenta más tarde.');
      }
    } else {
        this.messageSend = 'Ingresa la información necesaria en los campos.';
        alert('Ingresa la información necesaria en los campos.');
    }
  }
    },
    mounted() {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
            this.setActiveLanguage(savedLanguage);
        }
    }
};
</script>

<style scoped>

h1{
    font-size: 60px;
    font-weight: 600;
    line-height: 70px;
    text-align: left;
}

.form-control{
    overflow-y: hidden !important;
}
h6{
    font-size: 20px;
    font-weight: 400;
    line-height: 27.32px;
    text-align: left;
    color:#0CCF8D ;
}

p{
    font-family: Manrope;
font-size: 20px;
color: #191334;
font-weight: 400;
line-height: 27.32px;
text-align: left;

}
span{
    color: #191334;
}

.underline{
    text-decoration: underline;
}
.contacto{
    padding-top: 12%;
    position: static;
    min-height: 100vh;
    background-image: url('../assets/img//img_contactpage_bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}


.form-floating > label {
    position: absolute;
    top: 10px;
    left: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #191334;
   
  }
  .phone{
    display: none;
  }
  .form-floating > .form-control:focus ~ label::after, .form-floating > .form-control:not(:placeholder-shown) ~ label::after, .form-floating > .form-control-plaintext ~ label::after, .form-floating > .form-select ~ label::after{
      background: #FFFFFF80;
      color: #191334;
  }

  .form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    opacity: 0.7;
    color: #191334;
    border: none;
  }

 
  
  input:focus,
  input:active{
      outline: none ;
      margin-bottom: 0px;
      box-shadow: none ;
      border: none;
  }
 

  .form-floating{
    position: relative;
    border: none;
    border-radius: 10px;
    background-color:   #FFFFFF80;
 
  }
  .form-floating > .form-control{
    height: 70px;
    font-family: 'Manrope';
    color: #191334;
    border:none;
    background-color: transparent ;
    padding-left: 22px;
    padding-top: 32px;
    font-size: 22px;
    font-weight: 400;
    text-align: left;
  }


  .enviar{
    background-color:#0CCF8D ;
    color: #191334;
    font-size: 22px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    border-radius: 100px;
    border: none;
    padding: 2% 4% 2% 4%;

  }
   
 .m{
    max-width: 88%;
     margin: auto;
 }
 
 @media(max-width:990px){
    .m{
        max-width: 100%;
    }
 
    .phone{
        display: flex;

    }

    .desk{
        display: none;
    }
    .contacto{
    padding-top: 15%;
 
    height: fit-content;
 
    background-position: top left;
    padding-bottom: 9%;
}
 }


 @media(max-width:768px){
    p{
font-size: 16px;
line-height: 21.32px;
}

.contacto{
    padding-top: 25%;
    background-image: url('../assets/img//img_contactpage_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label {
    font-size: 14px;
 
    line-height: 16px;
 
  }

  form{
    width: 100%;
  }

  .form-floating > .form-control{
    height: 63px;
    padding-left: 19px;
    padding-top: 32px;
    font-size: 18px;
  }

  #contacto{
    padding-top: 15%;
    padding-bottom: 15%;
 
}


.form-floating > label {
    position: absolute;
    top: 10px;
    left: 17px;
    font-size: 15px;
 
    line-height: 16px;
 
   
  }
 

  .pb-5{
    padding-bottom: 10px !important;
  }

  p{
    margin-bottom: 5px !important;
  }

  .pb{
    padding-top: 5%;
  }

 }
 @media(max-width:500px){
    .contacto{
    padding-top: 30%;
 
}
 }
</style>