<template>
    <div>
        <b-container class="rec">
          <b-row>
            <b-col md="12">
              <h3> {{ translations.cert.recognitions }} </h3>
            </b-col>
            <b-col align-self="center" class="text-center" md="4" offset-md="2">
              <img class="img-fluid" src="../assets/img/logo_merca.png" alt="">
              <p class="mobile-text" v-html="translations.cert.merca"></p>
            </b-col>
            <b-col align-self="center" class="text-center" md="4">
              <img class="img-fluid" src="../assets/img/logo_amai.png" alt="">
              <p class="mobile-text" v-html="translations.cert.amai"></p>
            </b-col>
            <b-col align-self="end" class="text-center desktop-text" md="4" offset-md="2">
              <p v-html="translations.cert.merca"></p>
            </b-col>
            <b-col align-self="end" class="text-center desktop-text" md="4">
              <p v-html="translations.cert.amai"></p>
            </b-col>
          </b-row>
        </b-container>
    </div>


</template>


<script>
 
import esp from '../assets/json/es.json';
import en from '../assets/json/en.json';

export default {
  props: {
    activeLanguage: {
      type: String,
      required: true
    }
  },
 
  data() {
    return {
      translations: this.activeLanguage === 'eng' ? en : esp,
    }
  },
  watch: {
    activeLanguage(newLanguage) {
      this.translations = newLanguage === 'eng' ? en : esp;
    }
  },
 
}
</script>



<style scoped>

.cert{
    border-top: 1px solid #1D34A2;
    border-bottom: 1px solid #1D34A2;
    padding: 3%;
    margin-top: 3%;
}

.rec{
    padding: 3%;
    margin-top: 3%;
}
.rec img{
  padding: 10%;
}

.resp-img {
  width: 100%;
  height: auto;
  max-width: 290px;
 
}

h3{
  font-size: 35px;
  font-weight: 600;
  line-height: 47.81px;
}
p{
  color:#191334;
  font-size: 23px;
  font-weight: 400;
  line-height: 31.42px;
}
.w-100{
    display: none;
}
.desktop-text{
  display: block;
}
.mobile-text{
  display: none;
}
@media (max-width: 768px) {
.justify-content-around{
    justify-content: flex-start !important;
}
.w-100{
    display: flex;
}
.desktop-text{
  display: none;
}
.mobile-text{
  display: block;
}
h3{
 
 font-size: 26px;
  
 line-height: 35.52px;
  
 
 }
 p{
        font-size: 16px;
line-height: 21.32px;
    }

 
 }


 @media (max-width: 570px) {

    .content{
        max-width: 100%;
    }  .content2{
        max-width: 50%;
    }
    .cert{
    max-width: 90%;
    padding: 3%;
    margin-top: 8%;
}
 
 } 
</style>