<template>
  <div @scroll="handleScroll">
    <!-- MENU -->
    <MenuSection :color="color" :logo="logo" :key="color" :active-route="activeRoute"
      @update-active-route="setActiveRoute" @update-language="setActiveLanguage" :active-language="activeLanguage"></MenuSection>

    <!-- INTRO -->
    <div id="intro" class="section light">
      <b-container>
        <b-row class="justify-content-center">
          <b-col lg="8" md="10">
            <h2>{{ translations.intro3.title }}</h2>
            <h5 class="mt-4">{{ translations.intro3.description }}</h5>
          </b-col>
        </b-row>
        <b-row class="justify-content-center" style="margin-top: 10%;">
          <b-col lg="10">
            <h3>{{ translations.intro3.subtitle }}</h3>
          </b-col>
        </b-row>
        <b-row class="justify-content-center">
          <b-col >
            <img class="resp-img" src="../assets/img/img_soluciones.png"  alt="soluciones">
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!-- FOCUS -->
    <div class="section light">
      <b-container>
        <b-row class="justify-content-center" style="margin-top: -3%;">
          <b-col lg="10">
            <h3>{{ translations.focus.description }}</h3>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!-- EXPERTISE -->
    <div class="section light sec">
      <b-container>
        <b-row class="justify-content-center align-items-center">
          <b-col lg="7" md="5" >
            <img class="resp-img2" src="../assets/img/img_soluciones2.png" alt="soluciones">
          </b-col>
          <b-col lg="6" md="7" style="margin-left: -10%;" class="left">
            <h6 v-html="translations.expertise.description"></h6>
        </b-col>
        </b-row>
      </b-container>
    </div>

    <!-- SOLUCIONES -->
    <SolucionesSection class="section dark" :active-language="activeLanguage"></SolucionesSection>

    <!-- CONTACTO -->
    <ContactoSection class="section light" :active-language="activeLanguage"></ContactoSection>

    <!-- FOOTER -->
    <FooterSection :active-language="activeLanguage"></FooterSection>
  </div>
</template>


<script>
    import MenuSection from "./MenuSection.vue"
    import SolucionesSection from "./SolucionesSection.vue";
    import ContactoSection from "./ContactoSection.vue"
    import FooterSection from "./FooterSection.vue"
    import esp from '../assets/json/es.json';
    import en from '../assets/json/en.json';

    export default{
        components:{
            MenuSection, SolucionesSection, ContactoSection, FooterSection
        },
    data() {
        return {
            color: 'light',
            logo: require('../assets/img//img_logo_color.svg'),
            activeRoute: localStorage.getItem('activeRoute') || 'home',
            activeLanguage: localStorage.getItem('selectedLanguage') || 'eng',
            translations: localStorage.getItem('selectedLanguage') === 'esp' ? esp : en,
        }
    },
    mounted() {const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
          this.setActiveLanguage(savedLanguage);
        } else {
      this.translations = this.activeLanguage === 'eng' ? esp : en;
    }
    window.addEventListener('scroll', this.handleScroll);
    },
    methods: {setActiveRoute(route) {
         this.activeRoute = route;
         localStorage.setItem('activeRoute', route);
       },
       handleScroll() {
      const sections = document.querySelectorAll('.section');
      let currentSection = null;

      sections.forEach(section => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= 50 && rect.bottom >= 50) {
          currentSection = section;
        }
      });

      if (currentSection) {
        this.toggleColor(currentSection);
      }
    },
        toggleColor(section) {
            if (section.classList.contains('dark')) {
                this.color = 'dark';
                this.logo = require('../assets/img//img_logo_white.svg');
            } else {
                this.color = 'light';
                this.logo = require('../assets/img//img_logo_color.svg');
            }
        },
        setActiveLanguage(language) {
      this.activeLanguage = language;
      this.translations = language === "eng" ? en : esp;
        localStorage.setItem('selectedLanguage', language);
    }
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll);
    }
    }

</script>

<style scoped>

h2{
    font-size: 40px;
    font-weight: 600;
    line-height: 45px;
    text-align: center;
}

h3{
    font-size: 35px;
    font-weight: 600;
    line-height: 47.81px;
    text-align: center;
}

h6{
    font-size: 25px;
    font-weight: 600;
    line-height: 34.15px;
    text-align: left;
 
}
 
h5{
 color:#191334;
font-size: 25px;
font-weight: 400;
line-height: 34.15px;
 

}
 

 .bg-home{
    background-image: url('../assets/img//img_soluciones.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 680px;
 
 }
 .bg-img2{
    background-image: url('../assets/img//img_soluciones2.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 750px;
    overflow: auto;
 }
 #intro{
    padding-top: 10%;
    padding-bottom: 5%;
 } 
 

 .resp-img, .resp-img2{
    width: 100%;
    height: auto;
    margin-top: -3%;
 }
 @media (max-width: 1200px) {

#intro{
  padding-top: 15%;
  padding-bottom: 10%;
}

}

@media (max-width: 990px) {

.sec{
  padding-top: 7%;
  padding-bottom: 10%;
  margin-right: -16%;
}
.resp-img2{
    width: 200%;
    height: auto;
    margin-top: -3%;
    margin-left: -80%;
 }
}


@media(max-width:768px){
    h2  {
 
font-size: 35px;
line-height: 40px;
    }
    h3  {
 
 font-size: 25px;
 line-height: 30px;
     }

     h6{
    font-size: 22px;
 
    line-height: 31.15px;
 
} #intro{
    padding-top: 27%;
    padding-bottom: 10%;
 }  h5{
  font-size: 18px !important;
  line-height: 24px !important;
 }.mt-4{
  margin-top: 10px !important;
 }
 .resp-img  {
    width: 130%;
    height: auto;
    margin-top: 0%;
    margin-left: -15%;
 }
 .resp-img2{
    width: 100%;
    height: auto;
    margin-top: -15%;
    margin-left: 0%;
 }

 .left{
  margin-left: 0 !important;
  margin-top: -20%;
  margin-right: 15%;
 }



}@media(max-width:500px){
  .resp-img2{
    width: 120%;
    height: auto;
    margin-top: -15%;
    margin-left: -12%;
 }


}
 
</style>