<template>
  <div id="app">
    <router-view/>
  </div>
</template>


<script>

export default {
  name: 'app',
  components: {
  },
  beforeMount() {
    // window.addEventListener("load", this.onLoad);
    // window.addEventListener("beforeunload", this.onUnload);
  },
  beforeDestroy() {
    window.localStorage.setItem("selectedLanguage", "eng");
    window.addEventListener("beforeunload", this.onUnload);
    // window.removeEventListener("load", this.onLoad);
    // window.removeEventListener("beforeunload", this.onUnload);
  },
  methods: {
    onLoad(event) {
      console.log(event)
      window.localStorage.setItem("selectedLanguage", "eng");
    },
    onUnload(event) {
      console.log(event)
      window.localStorage.setItem("selectedLanguage", "eng");
    }
  }
}
</script>

<style>
#app {
  font-family: "Manrope", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #162B71;
  text-decoration: none;
  background-color: #D9E7F9;
  overflow-x: hidden;
} 
textarea{
    overflow-y: none;
    resize: none;
 }
.active a.nav-link  {
    font-weight: 700 !important;
  }
  .VueCarousel-navigation-next {
    background-image: url('/src/assets/img/icn_arrow_active_right.svg');
    position:absolute;
    bottom: 0 !important;
    right: 20% !important;
    top: 95% !important;
    background-color: transparent !important;
    height: 35px;
    width: 35px;
}
.VueCarousel-navigation-prev {
    background-image: url('/src/assets/img/icn_arrow_active_left.svg');
    position:absolute;
    bottom: 0 !important;
    right: 15% !important;
    top: 95% !important;
    height: 35px;
    width: 35px;
    left: auto !important;
}
 

.VueCarousel-navigation{
  display: flex !important;
  justify-content: center;
  position: relative !important;
  height: 60px !important;
  z-index: 10;

}
.VueCarousel-navigation-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  outline: none;
}

 
.VueCarousel-navigation-prev::before {
  background-image: url('/src/assets/img/icn_arrow_inactive_left.svg') !important;
}

.VueCarousel-navigation-next::before {
  background-image: url('/src/assets/img/icn_arrow_inactive_right.svg') !important;
}

.VueCarousel-navigation-button:active.VueCarousel-navigation-prev,
.VueCarousel-navigation-button:focus.VueCarousel-navigation-prev {
  background-image: url('/src/assets/img/icn_arrow_inactive_left.svg') !important;
}

.VueCarousel-navigation-button:active .VueCarousel-navigation-next::before,
.VueCarousel-navigation-button:focus .VueCarousel-navigation-next::before ,
.VueCarousel-navigation-button:active.VueCarousel-navigation-next,
.VueCarousel-navigation-button:focus.VueCarousel-navigation-next{
  background-image: url('/src/assets/img/icn_arrow_inactive_right.svg') !important;
}


.VueCarousel-dot {
  background-color: transparent !important;
  width: 40px !important;
  height: 40px !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  border: none !important;
 
}
.VueCarousel-dot-container {
  margin: 0 !important;
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  border: none !important;
  padding-right :  10% !important;
  outline: none !important;
 
  box-shadow: none !important;
}

.VueCarousel-dot--active {
  background-image: url('/src/assets/img//icn_arrow_inactive_left.svg') !important;border: none !important;
  outline: none !important;
  padding: 0 !important;
  margin-right: 1% !important;
}

.VueCarousel-dot:not(.VueCarousel-dot--active) {
  background-image: url('/src/assets/img//icn_arrow_active_left.svg') !important;border: none !important;  outline: none !important;
  padding: 0 !important;
  margin-right: 1% !important;
}
 
.VueCarousel-dot:nth-child(2n) {
  background-image: url('/src/assets/img//icn_arrow_active_right.svg') !important;border: none !important;  outline: none !important;
  padding: 0 !important;
}

.VueCarousel-dot--active:nth-child(2n) {
  background-image: url('/src/assets/img//icn_arrow_inactive_right.svg') !important;border: none !important;  outline: none !important;
  padding: 0 !important;
}


.b-button:focus,
.b-button:hover,
.b-button:active,
.b-button:visited,
.b-button:focus-visible,
.b-button:focus-within,
.b-button:focus:hover,
.b-button:focus:active,
.b-button:hover:focus,
.b-button:active:focus {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    text-decoration: none !important;
    cursor: pointer;
}

.btn:focus,
.btn:hover,
.btn:active,
.btn:visited,
.btn:focus-visible,
.btn:focus-within,
.btn:focus:hover,
.btn:focus:active,
.btn:hover:focus,
.btn:active:focus {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    text-decoration: none !important;
    cursor: pointer;
}

.dark.custom-toggler.collapsed {
 

 background-image: url('/src/assets/img/list_white.svg');
 
  width: 45px;
 
    height: 35px; 
}

.dark.custom-toggler {
 
  background-size: cover;
  background-image: url('/src/assets/img/x-lg.svg');  width: 35px;
 
 height: 35px; 
  border: none;  
  background-repeat: no-repeat;
  background-position: center;
}

.light.custom-toggler.collapsed ,.light2.custom-toggler.collapsed {
  background-image: url('/src/assets/img/list.svg');    width: 45px;
 
 height: 35px;
}
 
.VueCarousel-navigation-button:focus {
  outline: none !important;
}

.light.custom-toggler ,.light2.custom-toggler {
 
  background-size: cover;
   background-image: url('/src/assets/img/x-lg_dark.svg');  width: 35px;
 
 height: 35px; 
  border: none;  
  background-repeat: no-repeat;
  background-position: center;
}
a:hover {
  text-decoration: none !important;
}
a{
  text-decoration: none;
}
h5{
    font-size: 25px;
    font-weight: 400;
    line-height: 34.15px;
    text-align: center;
}.verde {
  color: #0CCF8D !important;
}
.sol{
    font-size: 20px;
    font-weight: 400;
    line-height: 27.32px;
    text-align: left;
    color:#191334;
 
 }
@media (min-width: 1400px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
        max-width: 1320px !important;
    }
}

@media (max-width: 993px) {
  .navbar-collapse{
  display: flex;
}
.navbar-nav.ml-auto{
  text-align: start;
  margin-left: 0 !important;
}

a.nav-link {
  justify-content: start !important;
}


}
 

@media(max-width:768px){
  h1{
  font-size: 35px !important;
  line-height: 34.48px !important;
  }
 
  a.nav-link{
  font-size: 18px !important;
  line-height: 24.59px !important;
  text-align: left;
  }
  .sol{
        font-size: 16px;
line-height: 21.32px;
    }
}


</style>
